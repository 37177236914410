* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo-container {
    margin-left: 10%;
    flex: 1;
}

.logo-header {
    max-width: 70px;
    height: 70px;
}

.menu {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-right: 9%;
    font-size: large;
    z-index: 100;
    font-weight: bolder;
}

.menu a {
    color: rgba(16, 76, 196, 0.9);
    text-decoration: none;
    /* text-shadow: 1px 0px 5px #000; */
}

.burger-icon {
    display: none;
    font-size: 24px;
    color: rgba(16, 76, 196, 0.9);
    cursor: pointer;
    z-index: 10000;
}
.logo-appance{
    height: 65px;
}

@media (max-width: 768px) {
    .burger-icon {
        display: flex;
    }

    .menu {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        background-color: #fff;
        width: 100%;
        padding: 20px;
        text-align: center;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    }

    .menu.open {
        display: flex;
    }
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    background-color: rgba(16, 76, 196, 0.9);
    color: #fff;
    font-size: large;
    font-weight: bold;
    border: none;
    cursor: pointer;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: rgba(16, 76, 196, 0.9);
    min-width: 160px;
    z-index: 1;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
    color: #fff;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.menu a {
    position: relative;
    color: rgba(16, 76, 196, 0.9);
    text-decoration: none;
    font-size: large;
    transition: color 0.3s ease-in;
}

.menu a::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 3px;
    background-color: rgba(16, 76, 196, 0.9);
    transition: width 0.3s ease-in;
}

.menu a:hover {
    color: rgba(16, 76, 196, 0.9);
    text-shadow: 1px 0px 1px #fff;
}

.menu a:hover::after {
    width: 100%;
}


.menu-item {
    cursor: pointer;
    position: relative;
    color: rgba(16, 76, 196, 0.9);
    text-decoration: none;
    font-size: large;
    transition: color 0.3s ease-in;
    font-family: 'Playfair Display', sans-serif;
    font-weight: bold;
}

.menu-item::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: rgba(16, 76, 196, 0.9);
    transition: width 0.3s ease-in, transform 1s ease-in-out;
    transform-origin: left;
    transform: scaleX(0);
}

.menu-item:hover {
    color: rgba(16, 76, 196, 0.9);
    text-shadow: 1px 0px 1px rgb(0, 0, 0);
}

.menu-item:hover::after {
    width: 100%;
    transform: scaleX(1);
}

.menu-item.active::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: rgba(16, 76, 196, 0.9);
    transition: width 0.3s ease-in, transform 1s ease-in-out;
    transform-origin: left;
    transform: scaleX(1);
}

@media (max-width: 460px) {
    .logo-header {
        max-width: 55px;
        height: 55px;
    }
}
@media (max-width: 400px) {
    .logo-header {
        max-width: 50px;
        height: 50px;
    }
}