*{
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}

.career-main{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.career-main-sub{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(61, 104, 188, 0.2);
    padding: 100px;
}
.box-job{
    width: 700px;
    height: 400px;
    border-radius: 40px;
    box-shadow: 20px 10px 38px rgba(0, 0, 0, 0.1);
    background-color: rgba(61, 104, 188, 0.2);
    margin: 50px;
}

.flex-box{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.flex-box-vertical{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.img-joinus{
    height: 500px;
    width: 500px;
}

.joinus-txt1{
    /* font-family: 'Playfair Display', sans-serif; */
    font-family: 'Domine', serif;
    text-align: left;
    line-height: 1.6;
}

.joinus-btn1{
    background-color: rgba(16, 76, 196, 0.9);
    outline: none;
    border: none;
    border-radius: 20px;
    box-shadow: 4px 0px 14px rgba(0, 0, 0, 0.3);
    color: white;
    padding: 20px;
    font-family: 'Domine', serif;
    font-weight: bold;
    font-size: 22px;
    margin-top: 25px;
    margin-left: 15px;
    cursor: pointer;
}
.joinus-btn1:hover{
    box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.3);
    background-color: rgba(16, 76, 196, 1);
}

@media (max-width: 768px) {
    .img-joinus{
        height: 300px;
        width: 300px;
    }
    .joinus-txt1{
        font-family: 'Domine', serif;
        text-align: left;
        line-height: 1.6;
        font-size: 14px;
    }
    .joinus-btn1{
        background-color: rgba(16, 76, 196, 0.9);
        outline: none;
        border: none;
        border-radius: 20px;
        box-shadow: 4px 0px 14px rgba(0, 0, 0, 0.3);
        color: white;
        padding: 16px;
        font-family: 'Domine', serif;
        font-weight: bold;
        font-size: 18px;
        margin-top: 25px;
        margin-left: 15px;
        cursor: pointer;
    }

}

@media (max-width: 568px) {
    .joinus-txt1{
        font-family: 'Domine', serif;
        text-align: left;
        line-height: 1.6;
        font-size: 14px;
    }
    .img-joinus{
        height: 240px;
        width: 170px;
    }
    .career-main-sub{
        justify-content: center;
    }
}
@media (max-width: 300px) {
    .img-joinus{
        height: 240px;
        width: 140px;
    }
        .joinus-txt1{
        font-family: 'Domine', serif;
        text-align: left;
        line-height: 1.6;
        font-size: 12px;
    }
}