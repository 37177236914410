* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* blurred on scroll clear
 */
/* Add these styles to your existing CSS file */
.slider-imges-main {
  opacity: 0.6;
  filter: blur(5px);
  border-radius: 30%;
  transition: border-radius 0.5s ease-in, opacity 0.5s ease-in 0.5s; /* Add smooth transition effect */
}
.home-first-main {
  overflow-x: hidden;
}
body{
  overflow-x: hidden;
}
.slider-imges-main.expanded {
  opacity: 1;
  border-radius: 0%;
  filter: blur(0);
}
.slider-box-img {
  opacity: 0; /* Initial opacity set to 0 for fade-in effect */
  transition: opacity 1s ease-in 1s; /* Add smooth transition effect with 0.5s delay */
}

.slider-box-img.visible {
  opacity: 1; /* Set opacity to 1 for clear view */
}

.homeservices-main {
  opacity: 0.4;
  filter: blur(5px);
  border-radius: 30%;
  transition: border-radius 0.4s ease-in, opacity 0.2s ease-in 0.3s; /* Add smooth transition effect */
}

.homeservices-main.expanded {
  opacity: 1;
  border-radius: 0%;
  filter: blur(0);
}

.home-main{
    width: 100vw;
    height: 100vh;
}
.home-background {
    position: relative; /* Add position relative to the container for absolute positioning of ::before */
    background-image: url("../Images/appancebackground34.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 85vh;
    width: 100vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10%;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.9);
    position: relative;
}

.home-background::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1; /* Ensure the overlay is below the text */
}

.z-idx-mx {
  z-index: 999;
  width: 35vw;
  height: auto;
  border-radius: 20px;
  overflow: hidden;
  padding: 50px;
}

.home-text-img {
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: slideAnimation 10s infinite;
  font-size: 52px;
  text-shadow: 1px 4px 10px #fff;
  color: #fff;
  font-family: 'Source Serif 4', sans-serif;
  text-align: center;
}

@keyframes slideAnimation {
  0%, 100% {
    opacity: 0;
    transform: translateX(-100%);
  }
  5%, 25% {
    opacity: 1;
    transform: translateX(0);
  }
  30%, 50% {
    opacity: 0;
    transform: translateX(100%);
  }
  55%, 75% {
    opacity: 1;
    transform: translateX(0);
  }
  80%, 95% {
    opacity: 0;
    transform: translateX(-100%);
  }
}


.form-home{
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-home{
  background-color: white;
  color: green;
  border-radius: 50px;
  padding: 20px;
  font-family: 'Source Serif 4', sans-serif;
  font-weight: bold;
  z-index:999;
}
.home-top-box{
    height: 30%;
    width: 70%;
    margin-bottom: -10vh;
    border: none;
    background-color: rgb(79, 126, 219);
    box-shadow: 1px 1px 8px rgba(0,0,0,0.5);
    display: flex;  
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    z-index: 999;

}
.responsive-img{
    margin-top: 150px;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px) rotate(5deg); }
  50% { transform: translateX(5px) rotate(-5deg); }
  75% { transform: translateX(-5px) rotate(5deg); }
  100% { transform: translateX(0); }
}

/* Apply the animation to the images */
.home-background img {
  max-width: 100%;
  display: none;
}

.home-background img.visible {
  display: block;
  animation: shake 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

.homeservices-main{
  background-color: #fff;
  height: 80vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1% 0px;
}
.homeservices-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.home-service-img{
  background-color: rgba(100, 135, 221, 0.1);
  border-radius: 0px 500px 50px 0px;
  width: 60vw;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.size-web{
  width: 75%;
  height: 85%;
}

.size-mobile{
  width: 70%;
  height: 120%;
}
.margin-neg{
  margin-left:-20%;
}
@keyframes bounce {
  0%, 100% {
    transform: translateY(0); /* Start and end position (no translation) */
  }
  50% {
    transform: translateY(-60px); /* Middle position (translate 100px up) */
  }
}

.small-margin{
  margin-left: 20%;
}
/* Apply the animation to the 'animated' class */
.animated {
  animation: bounce 3s infinite; /* Use 'bounce' animation with a 2-second duration, and repeat infinitely */
}
.home-servicetwo-img{
  background-color: rgba(100, 135, 221, 0.1);
  border-radius: 400px 0px 0px 400px;
  width: 50vw;
  height: 70vh;
}

.digitalmarketing-home-main{
  background-color: rgb(79, 126, 219);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70vh;
}
.digitalmarketing-home-sub1{
  width: 35%;
}
.digitalmarketing-home-sub2{
  width: 55%;
  padding: 30px;
}

.input-home{
  width: 25vw;
  height: 8vh;
  border: none;
  outline: none;
  border-radius: 30px;
  padding: 10px;
  margin: 50px;
  text-align: center;
  color: rgba(16, 76, 196,1);
  font-family: 'Source Serif 4', sans-serif;
  font-weight: bold;
  z-index: 999;
}

.submit-home{
  width: 6vw;
  height: 6vh;
  background-color: rgb(79, 126, 219);
  color: #fff;
  border-radius: 30px;
  border: none;
  box-shadow: 1px 1px 12px rgba(0,0,0,0.6);
  cursor: pointer;
  transition: height 0.5s;
  z-index: 999;
}

.submit-home:hover{
  box-shadow: 1px 1px 12px rgba(0,0,0,0.9);
  height: 6.5vh;
}

.hidden {
  display: none;
}

.visible {
  display: block;
}


.homeclient-main{
  height: 50vh;
  margin-bottom: 20%;
}
.home-client-img{
  /* border-radius: 100px !important; */
}

.slide-height-client {
    height: 400px;
    box-shadow: 10px 0px 14px black;
    border-radius: 150px;
}

* {
  padding: 0;
  margin: 0;
}

.carousel {
  overflow: hidden;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}


.homegif{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
}


.blur {
  filter: blur(5px); /* Adjust the blur amount as needed */
  transition: filter 0.5s ease; /* Transition for smooth effect */
}

.animated-text-container {
  text-align: center;
  margin-top: 50px;
}

.animated-text {
  display: inline-block;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 50px;
  opacity: 0;
  animation: fadeIn 1s forwards;
  color: #fff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home-txt1{
  color: rgba(16, 76, 196,1);
  font-size: 22px;
  font-family: 'Domine', serif;
  margin: 30px;
  padding: 10px;
  line-height: 32px;
}
.home-txt2{
  line-height: 32px;
  color: rgb(79, 126, 219);
  font-size: 42px;
  font-family: 'Stardos Stencil', sans-serif;
  text-shadow: 2px 2px 0 rgba(255, 255, 255, 0.5), 4px 4px 0 rgba(0, 0, 0, 0.5);
}
.home-txt3{
  font-family: 'EB Garamond', sans-serif;
  font-weight: bold;
}
.home-txt4{
  font-family: 'EB Garamond', sans-serif;
  font-weight: bold;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2), 4px 4px 0 rgba(0, 0, 0, 0.2), 6px 6px 0 rgba(0, 0, 0, 0.2), 8px 8px 0 rgba(0, 0, 0, 0.2);
  font-size: 42px;
}
.home-txt5{
  font-family: 'Playfair Display', sans-serif;
  color: #fff;
  font-size: 26px;
}
.home-txt6{
  font-family: 'Playfair Display', sans-serif;
  color: #fff;
  font-size: 26px;
}

.scroll-to-top {
position: fixed;
z-index: 100;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgba(16, 76, 196, 1); /* Customize the background color */
  color: white; /* Customize the text color */
  border-radius: 50%;
  font-size: 20px;
}
.homebillboard-main{
  height: 120vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.1);
  margin: auto;
  margin-bottom: 100px;
  padding: 25px;
}

.billboard-img{
  height: 110vh;
  width: 65vw;
  margin: auto;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}
.size-billboard{
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  height: 95%;
  width: 80%;
  border-radius: 30px;
}

.slide-height-client{
    height: 450px;
  }


  .slider-imges-main {
  /* margin-top: 20vh; */
  height: 70vh;
  width: 100vw;
  display: flex;
  align-items: center !important;
  justify-content: space-around !important;
  background-image: url("../Images/appancebackgroundfortextimg.PNG");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: 15vh;
}

.slider-box-img {
  width: 70%;
  height: auto;
  border-radius: 10px;
  /* border: 50px solid black; */
  box-shadow:  8.0px 16.0px 16.0px hsl(0deg 0% 0% / 0.25);
}



@media (max-width: 1200px) {
    .digitalmarketing-home-main{
    height: 50vh;
  }

  .digitalmarketing-home-sub1{
    width: 25%;
  }
  
  .digitalmarketing-home-sub2{
    width: 55%;
    padding: 15px;
  }
  .visible {
    height: 28vh;
    width: 45vw;
  }
  .home-txt5{
    font-size: 18px;
  }
  .home-txt6{
    font-size: 18px;
  }
    .z-idx-mx {
    width: 45vw;
    height: auto;
    padding: 30px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .home-text-img {
    font-size: 32px;
    text-shadow: 1px 4px 10px #fff;
  }
}

@media (max-width: 768px) {
  .z-idx-mx {
    width: 60vw;
    height: auto;
    padding: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .home-text-img {
    font-size: 32px;
    text-shadow: 1px 4px 10px #fff;
  }
  .home-background{
    height: 70vh;
  }
  .submit-home{
    width: 20%;
    height: 40%;
    border-radius: 20px;
  }
  .input-home{
    width: 50%;
    height: 7vh;
    border: none;
    outline: none;
    border-radius: 30px;
    padding: 10px;
    margin: 20px;
    text-align: center;
    color: rgba(16, 76, 196,1);
    font-family: 'Source Serif 4', sans-serif;
    font-weight: bold;
  }
  .home-top-box{
    width: 75%;
  }
  .animated-text {
    font-size: 24px;
  }
  .homeservices-main{
    background-color: #fff;
    height: 80vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20% 0px;
  }
  .small-margin{
    margin-left: 10%;
  }
  .size-mobile{
    display: none;
    width: 20%;
    height: 30%;
  }
  .home-txt1{
    color: rgba(16, 76, 196,1);
    font-size: 18px;
    font-family: 'Domine', serif;
    margin-left: 10px;
    padding: 5px;
    line-height: 22px;
  }
  .margin-neg{
    height: 90%;
    width: 100%;
  }

  /* digital marketing */
  .digitalmarketing-home-main{
    height: 50vh;
  }

  .digitalmarketing-home-sub1{
    width: 25%;
  }
  
  .digitalmarketing-home-sub2{
    width: 55%;
    padding: 15px;
  }
  .visible {
    height: 28vh;
    width: 45vw;
  }
  .home-txt5{
    font-size: 16px;
  }
  .home-txt6{
    display: none;
  }

  /* client */
  .slide-height-client{
    height: 200px;
  }
}

@media (max-width: 550px) {
  .home-background{
    height: 65vh;
    background-image: url("../Images/backgroundmobilehome.png");
  }
  .home-txt1{
    color: rgba(16, 76, 196,1);
    font-size: 15px;
    font-family: 'Domine', serif;
    margin-left: 10px;
    padding: 2px;
    line-height: 16px;
  }
  .home-service-img{
    width: 50vw;
  }
  .margin-neg{
    height: 90%;
    width: 140%;
  }
  .size-web{
    margin-top: 150px;
    width: 45vw;
    height: 50%;
  }

  /* billboard */
  .homebillboard-main{
    height: 100vh;
    width: 100vw;
    background-color: rgba(100, 135, 221, 0.1);
    margin-bottom: 100px;
    padding: 25px;
  }
  .billboard-img{
    height: 90vh;
    width: 75vw;
    margin: auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
  }
  .size-billboard{
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
    height: 95%;
    width: 90%;
    border-radius: 20px;
  }
}
@media (max-width: 460px) {
    .z-idx-mx {
    width: 70vw;
    height: auto;
    padding: 30px;
  }
  .home-text-img {
    margin-top: 100px;
    font-size: 30px;
    text-shadow: 1px 4px 10px #fff;
    padding-top: 60px;
  }
  .home-txt1{
    color: rgba(16, 76, 196,1);
    font-size: 13px;
    font-family: 'Domine', serif;
    margin-left: 10px;
    padding: 3px;
    line-height: 18px;
    margin-right: 0px;
  }
  .size-billboard{
    width: 94%;
  }
  .digitalmarketing-home-main{
    height: 45vh;
  }

}

@media (max-width: 400px) {
  .home-background{
    height: 60vh;
  }
.submit-home{
    width: 20%;
    height: 45%;
    border-radius: 20px;
  }
  .input-home{
    width: 55%;
    height: 6vh;
  }
  .home-top-box{
    width: 88%;
  }
  .home-txt4{

  }
  .home-txt1{
    color: rgba(16, 76, 196,1);
    font-size: 13px;
    margin-left: 4px;
    line-height: 16px;
    padding: 1px;
  }

  /* billboard */
  .homebillboard-main{
    height: 100vh;
    width: 100vw;
    background-color: rgba(100, 135, 221, 0.1);
    margin-bottom: 100px;
    padding: 25px;
  }
  .billboard-img{
    height: 90vh;
    width: 80vw;
    margin: auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
  }
  .size-billboard{
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
    height: 90%;
    width: 92%;
    border-radius: 20px;
  }
  /* digital marketing */

  .home-txt5{
    font-size: 14px;
  }
}

.home-first-carousel{
  height: 350px;
  /* width: auto; */
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.home-first-carousel-sub{
  height: 300px;
  width: 300px;
  /* background-color: rgb(92, 131, 246); */
  box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 120px 10px 120px;
  border-bottom: 10px solid rgb(92, 131, 246);
  border-right: 10px solid rgb(92, 131, 246);
}
.carousel-img{
  width: 300px;
  height: 290px;
  border-radius: 50px;
}
.txt-center{
  text-align: center;
}
.home-txt7{
  font-family: 'Domine', serif;
  font-size: 42px;
  color: rgb(92, 131, 246);
  text-shadow: 2px 2px 4px rgba(92, 131, 246, 0.8);
}

.daj-home{
  width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* homesliderimagetop */
.slider-container {
  width: 100%;
  /* overflow: hidden; */
  position: relative;
  max-width: 800px; /* Set your desired max width */
  align-items: center; /* Align items at the start */
  
}

.slider-item {
  display: none;
  transition: transform 0.2s ease-in-out;
}

.slider-item.active {
  display: block;
} 

.image-wrapper {
  animation: none; /* Disable animation by default */

}

.image-wrapper.active {
  animation: shake 0.1s ease; /* Apply shake animation when image is active */
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25%, 75% {
    transform: translateX(-15px);
  }
  50% {
    transform: translateX(15px);
  }
}

img {
  width: 100%;
  height: auto;
}

/* Responsive Styles */
@media screen and (max-width: 600px) {
  .slider-container {
    max-width: 100%;
  }
  .slider-imges-main {
    margin-top: 20vh;
    height: 40vh;
    width: 100vw;
    display: flex;
    align-items: flex-start !important;
    justify-content: space-around !important;
    background-image: url("../Images/appancebackgroundhomeforwithtext.png");
    background-repeat: no-repeat;
    background-size: contain;
    padding: 10px;
  }
  .home-txt7{
    font-size: 22px;
  }
  .home-first-carousel{
    margin-top: 100px;
  }
  .home-first-carousel-sub{
    height: 220px;
    width: 250px; 
    margin: 10px 100px 10px 100px;
  }
.carousel-img{
  width: 250px;
  height: 220px;
  border-radius: 50px;
}
}
@media screen and (max-width: 380px) {
  .home-txt7{
    font-size: 18px;
  }
  .home-first-carousel{
    margin-top: 100px;
  }
  .home-first-carousel-sub{
    height: 220px;
    margin: 10px 80px 10px 80px;
  }

    .home-text-img {
      margin-top: 100px;
      font-size: 24px;
      text-shadow: 1px 4px 10px #fff;
      padding-top: 60px;
    }
}

