* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.contactus-background{
    background-size: 100% 100%;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.contactus-box{
    margin-top: 5%;
    width: 75%;
    height: 80%;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
.callbox{
    width: 50%;
    height: 80vh;
    background-image: url("../Images/appance_contactusgif.gif");
    background-size: 100% 100%;
    border-radius: 20px 0px 0px 20px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.form-box{
    width: 60%;
    background-color: rgb(79, 126, 219);
    height: 100%;
    border-radius: 0px 20px 20px 0px;

}
.form-main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px;
}

.input-form{
    border: none;
    border-bottom: 2px solid black;
    background-color: #fff;
    outline: none;
    height: 8vh;
    width: 30vw;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    margin-bottom: 5%;
    border-radius: 30px;
}
.textarea-form{
    height: 20vh;
    width: 30vw;
    resize: none;
    padding: 10px;
    font-family: 'Domine', serif;
    font-weight: bold;
}
.submit-form {
    border: none;
    background-color: white;
    color: rgb(79, 126, 219);
    border-radius: 40px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    width: 10vw;
    height: 5vh;
    transition: width 0.5s; /* Add a transition to the width property */
}

.submit-form:hover {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    width: 12vw;
    font-weight: bold;
}
.social-media-icon-contactus{
    color: rgb(79, 126, 219);
    height: 40px;
    width: 50px;
    cursor: pointer;
}

.social-media-icon-faphone{
    color: rgb(79, 126, 219);
    height: 34px;
    width: 50px;
    cursor: pointer;
}
.popup-contactus{
    background-color: white;
    color: green;
    border-radius: 50px;
    padding: 20px;
    font-family: 'Source Serif 4', sans-serif;
    font-weight: bold;
}
.contactus-txt1{
    font-family: 'Domine', serif;
    font-weight: bold;
}
.contactus-txt2{
    font-family: 'EB Garamond', sans-serif;
    font-weight: bold;
}
.contactus-txt3{
    font-family: 'Playfair Display', sans-serif;
    font-weight: bold;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1), 2px 2px 0 rgba(0, 0, 0, 0.1), 6px 6px 0 rgba(0, 0, 0, 0.1), 8px 8px 0 rgba(0, 0, 0, 0.1);
}
.mobile-view-icons{
    display: none;
}
@media (max-width: 600px) {
    .callbox{
        display: none;
    }
    .form-box{
        width: 100%;
        border-radius: 20px;
    }
    .input-form{
        border: none;
        border-bottom: 3px solid black;
        height: 7vh;
        width: 55vw;
        margin-bottom: 12%;
    }
    .submit-form {
        border: none;
        background-color: white;
        color: rgb(79, 126, 219);
        border-radius: 40px;
        font-weight: bold;
        font-size: 18px;
        cursor: pointer;
        width: 20vw;
        height: 5vh;
        transition: width 0.5s; /* Add a transition to the width property */
    }

    .submit-form:hover {
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
        width: 25vw;
        font-weight: bold;
    }
    .social-media-icon-faphone{
        color: rgb(255, 255, 255);
        height: 34px;
        width: 50px;
        cursor: pointer;
    }
    .social-media-icon-contactus{
        color: rgb(255, 255, 255);
        height: 40px;
        width: 50px;
        cursor: pointer;
    }

    .social-media-icon-faphone{
        color: rgb(255, 255, 255);
        height: 34px;
        width: 50px;
        cursor: pointer;
    }
    .mobile-view-icons{
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-top: 40px;
    }
}
@media (max-width: 450px) {
    .input-form{
        border: none;
        border-bottom: 3px solid black;
        height: 7vh;
        width: 55vw;
        margin-bottom: 12%;
    }
}